<div *ngIf="memo"
    class="executive-container">
  <app-credit-warning-box [document]="memo" [style]="'right'"></app-credit-warning-box>
  <p class="title py-2">
    <span class="font-weight-bold f-20 ml-2 title-clamp">
      <i class="display-lg-none fas fa-chevron-left f-20 pointer pr-2 pt-2 pb-2"
          (click)="closeToggle('close')"></i>
      {{ memo.subject || ('MEMOS.UNTITLED'| translate) }}
    </span>
  </p>
  <div class="row no-gutters my-4"
      style="justify-content: flex-end">
    <div class="col-md-12 col-sm-12 text-right"
         [ngClass]="memo.is_review ? 'col-lg-auto' : 'col-lg-12'">
      <div class="row no-gutters" style="float:right">
        <button class="btn btn-primary col-auto p-2 ml-3 color-disabled"
            [ladda]="isLoading && actionType === 'ack-review'"
            [disabled]="memo.is_review"
            data-style="zoom-in"
            (click)="openAskReviewerModal()">
          <span>
            {{'APPROVAL.ASK-REVIEWER'| translate}}
          </span>
        </button>
        <button class="btn btn-danger col-auto p-2 ml-3" *ngIf="!memo.is_review"
            [ngbTooltip]="tooltipTerminate"
            placement="bottom"
            [disabled]="isLoading || (isContract && !memo.can_terminate_contract)"
            [ladda]="isLoading && actionType === 'terminate'"
            data-style="zoom-in"
            (click)="openConfirmModal('terminate')">
          <svg-icon class="my-auto"
              src="assets/images/icons/icon_discard.svg"
              [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
          <span>
            {{'APPROVAL.TERMINATED'| translate}}
          </span>
        </button>
        <button class="btn btn-warning col-auto p-2 ml-3"
            placement="bottom"
            *ngIf="!memo.is_review && !isContract"
            [disabled]="isLoading"
            [ladda]="isLoading && actionType === 'reject'"
            data-style="zoom-in"
            (click)="openConfirmModal('reject')">
          <svg-icon src="assets/images/icons/icon_reject.svg"
              [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
          <span class="text-white">
            {{'APPROVAL.REJECTED'| translate}}
          </span>
        </button>
        <button class="btn btn-success col-auto p-2 ml-3" *ngIf="!memo.is_review"
            (click)="onApproveButtonClick()"
            [disabled]="isLoading || (isContract && !memo.can_approve_contract)"
            [ladda]="isLoading && actionType === 'approve'"
            data-style="zoom-in">
          <svg-icon src="assets/images/icons/icon_approve.svg"
              [svgStyle]="{ 'height.px': 15, 'width.px': 15 }"></svg-icon>
          <span>
            {{ 'APPROVAL.APPROVED' | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="row no-gutter">
    <div class="menu-bar col-12">
      <div class="col-sm-6 d-flex align-items-center justify-content-end"
          *ngIf="memo.due_date">
        <span class="text-red font-weight-bold">
          {{'MEMOS.DUE-DATE'| translate}} {{ memo.due_date | humanDateTime}}
        </span>
      </div>
    </div>
  </div>

  <div [ngSwitch]="viewType"
      class="panel-container mx-1">
    <app-selection-tab [items]="selectionTabItems"
        [showArrow]="!isDesktop"
        [checkPage]="'pageDetail'"
        [themeList]="themeList"
        (selectedItem)="selectView($event)">
    </app-selection-tab>
    <div *ngSwitchCase="0"
        class="pdf-shadow panel-content-container">
      <div class="pt-3 mb-4"
          style="max-width: 220mm; width: 100%; margin: auto;">
        <app-approval-progress-bar [approvalCount]="memo?.current_level === 0 ||
          (memo?.status === 'approved' && memo?.current_level === memo?.max_level)
          ? memo?.current_level: memo?.current_level - 1"
            [approvalTotal]="memo?.max_level"
            [progressColor]="memoStatusColorSet?.primary"
            [textColor]="memoStatusColorSet?.primary"
            [backgroundColor]="memoStatusColorSet?.secondary">
        </app-approval-progress-bar>
      </div>
      <div class="pdf-view p-0"
          *ngIf="memo.memo_type ==='upload' || memo.memo_type === 'contract'">
        <div class="page-control w-100 position-relative justify-content-center align-items-center">
          <svg-icon src="assets/images/icons/back2.svg"
              class="pointer"
              [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
              style="width: 20px;height: 25px;margin-right: 10px;"
              (click)="changeDocumentPageBack()">
          </svg-icon>
          <div ngbDropdown
              class="d-inline-block"
              autoClose="true">
            <span>{{"UPLOAD.PAGE"|translate}}</span>
            <button class="btn page-btn mx-1"
                ngbDropdownToggle>{{currentDocumentPage}}</button>
            <span>{{"UPLOAD.OF"|translate}}&nbsp;{{totalDocumentPage}}</span>
            <div ngbDropdownMenu>
              <ng-container *ngFor="let page of documentPages">
                <div style="margin: 5px;">
                  <button ngbDropdownItem
                      [ngClass]="{'active': page === currentDocumentPage}"
                      (click)="changeDocumentPage(page)">{{page}}</button>
                </div>
              </ng-container>
            </div>
          </div>
          <svg-icon src="assets/images/icons/next2.svg"
              class="pointer"
              [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
              style="width: 20px;height: 25px;margin-left: 10px;"
              (click)="changeDocumentPageNext()">
          </svg-icon>
          <div class="d-none d-md-block icon position-absolute pointer p-1"
              style="right: 10px"
              (click)="windowDownload(); sendLogDownload(memo?.id)">
            <svg-icon src="assets/images/icon_download2.svg"
                [svgStyle]="{'width.px': 13, 'height.px': 13 ,'fill': '#102a44'}"
                [style.--c]="themeList?.header_table_color"></svg-icon>
            <span [style.color]="themeList?.header_table_color"
                class="underline ml-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
          </div>
        </div>
        <div class="page-view pdf-shadow bg-color"
            *ngIf="memo"
            (click)="previewModal = true">
          <app-pdf-signature-customizer
              *ngIf="(memo?.memo_type === 'upload' && memo?.upload?.uploaded_pdf) ||
              (memo.memo_type ==='contract'&& memo?.contract?.uploaded_pdf)"
              [scrollbarGutterStable]="true"
              [allowEdit]="false"
              [controlPageFromOutside]="true"
              [pdfPath]="{url: memo.signed_document,httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders:null)}"
              [memoNumber]="memo.memo_number"
              [(selectPageFromOutside)]="currentDocumentPage"
              [(markers)]="previewSignature"
              [isApprovalRequest]="true"
              (sendLoadingPage)="isDocumentLoadingPage = $event"
              (sendTotalPage)="totalDocumentPage = $event">
          </app-pdf-signature-customizer>
        </div>
      </div>
      <div class="panel-content overflow-hidden"
          *ngIf="memo.memo_type !=='upload' && memo.memo_type !=='contract'">
        <app-pdf-viewer [pdfFile]="pdfSrc"
            class="pdf-custom"
            [scrollbarGutterStable]="true"
            [showDownload]="true"
            (clickPreview)="previewModal = true"
            (downloaded)="windowDownload();sendLogDownload(memoId)"></app-pdf-viewer>
      </div>
    </div>
    <div *ngSwitchCase="1"
        class="pdf-shadow panel-content p-4">
      <div class="detail-title">
        {{ 'APPROVAL.TITLE' | translate }}
      </div>
      <div class="detail-content">
        {{ memo.subject || '-' }}
      </div>
      <hr />
      <div class="detail-title">
        {{ (isContract? 'CONTRACT.Contract number':'MEMOS.MEMO-NUMBER') | translate }}
      </div>
      <div class="detail-content">
        {{ memo.memo_number || '-'}}
      </div>
      <div *ngIf="memo.memo_type ==='upload'"
          class="detail-content f-11 mt-1">
        {{'MEMOS.UPLOAD-MEMO-TYPE-DETAIL' | translate}} :
        {{ translate.currentLang === 'th' ? memo.upload.upload_memo_type_detail?.name :
        memo.upload.upload_memo_type_detail?.name_en ? memo.upload.upload_memo_type_detail?.name_en :
        memo.upload.upload_memo_type_detail?.name }}
      </div>
      <hr />
      <div class="detail-title">
        {{ 'APPROVAL.CREATED-BY' | translate }}
      </div>
      <div class="detail-content">
        {{ memo.created_user_name }}
      </div>
      <hr />
      <ng-container *ngIf="!isContract">
        <div class="detail-title">
          {{ 'MEMOS.Expired date' | translate }}
        </div>
        <div class="detail-content">
          {{ date || ('MEMOS.No Expiration Date' | translate) }}
        </div>
        <hr />
      </ng-container>

      <div class="detail-title">
        {{ 'MEMOS.CC' | translate }}
      </div>
      <div class="detail-content">
        <span *ngFor="let person of memo.cc_list;let i = index">
          {{ translate.currentLang === 'en' ? person.context?.name_en || person.label : person.label }}
          <span *ngIf="i !== memo.cc_list.length - 1">, </span>
        </span>
        <span *ngIf="!memo.cc_list.length">{{ 'APPROVAL.NO-CC' | translate }}</span>
      </div>
      <hr />
      <ng-container *ngIf="!isContract">
        <div class="row">
          <div class="col-6">
            <div class="detail-title">
              {{'FEED.ANNOUNCEMENT' | translate}}
            </div>
            <div class="detail-content">
              <span *ngIf="memo.announcements.length">
                {{ memo.announcements.length }} {{'MEMOS.DEPARTMENT' | translate}}
              </span>
              <span *ngIf="!memo.announcements.length">{{'MEMOS.No Announcement' | translate}}</span>
            </div>
          </div>
          <div class="icon col-6"
              [style.--c]="themeList?.header_table_color"
              style="justify-content: end;">
            <div *ngIf="memo.created_user === createUser"
                class="pointer"
                (click)="openAnnouncement(announcementPopup,true)">
              <svg-icon src="assets/svg/edit-v3.svg"
                  [ngbTooltip]="'MEMOS.EDIT'|translate"
                  placement="bottom"
                  [container]="'body'"
                  [svgStyle]="{ 'fill': '#2d5b8f', 'height.px': 15, 'width.px': 15}"></svg-icon>
            </div>
            <div *ngIf="memo.announcements.length !== 0"
                class="pointer"
                (click)="openAnnouncement(announcementPopup, false)">
              <svg-icon src="assets/images/icons/report-ack.svg"
                  [ngbTooltip]="'MEMOS.REPORT'|translate"
                  placement="bottom"
                  [container]="'body'"
                  [svgStyle]="{'width.px': 15, 'height.px': 15}"></svg-icon>
            </div>
          </div>
        </div>

        <hr />
      </ng-container>
    </div>
    <div *ngSwitchCase="2"
        class="pdf-shadow panel-content">
      <app-loa-table [data]="tableInputData"
          [document]="memo"
          [loaGroup]="memo.loa_group"
          [background]="memoStatusColorSet.secondary"></app-loa-table>
    </div>
    <div *ngSwitchCase="3"
        class="pdf-shadow panel-content">
      <app-memo-comment [data]="memo.id"
          [normalHeight]="true"
          [isInOut]="isInOut"
          [actionType]="memo?.status"></app-memo-comment>
    </div>
    <div *ngSwitchCase="4"
        class="pdf-shadow panel-content">
      <app-memo-real-attachment [memo]="memo" [isInOut]="isInOut"></app-memo-real-attachment>
    </div>
    <div *ngSwitchCase="5"
        class="pdf-shadow panel-content">
      <app-memo-real-reference [memo]="memo"></app-memo-real-reference>
    </div>
    <div *ngSwitchCase="6"
        class="pdf-shadow panel-content">
      <app-memo-real-history [memo]="memo"></app-memo-real-history>
    </div>
  </div>

  <app-approval-confirm-modal #confirmModalComponent
      [(reason)]="reason"
      [isSaved]="isLoading"
      [themeList]="themeList"
      (confirm)="onConfirmModalSubmit($event)"
      [memos]="memo"
      (closeModal)="close()">
  </app-approval-confirm-modal>

  <app-approval-confirm-modal #approveConfirmModal
      [enableReason]="false"
      [isSaved]="isLoading"
      [themeList]="themeList"
      [memos]="memo"
      (confirm)="checkOtpModal()">
  </app-approval-confirm-modal>

  <app-select-sign-method-modal #selectSignMethodModalComponent
      [customTextSignature]="true"
      [enableTextSignature]="true"
      [enableUsingStoredSignature]="hasStoredSignature"
      [enableUploadSignature]="true"
      [numberOfSignature]="memo?.upload?.numbers_of_signature"
      [countPage]="memo?.upload?.count_page"
      [(otp)]="otp"
      [(signature)]="signature"
      [themeList]="themeList"
      [otpEnable]="false"
      [memoType]="memo.memo_type"
      [memoId]="memo.id"
      [isNeedSign]="!hasStoredSignature"
      [isSaved]="isLoading"
      (confirm)="confirmOtpSubmit($event)"
      (closeModal)="close()"
      (isUseStoredChange)="onIsUseStored($event)">
  </app-select-sign-method-modal>

  <app-confirm-modal #confirmAlertMessage
      [memoDetail]="memo"
      (confirmOtpSubmit)="confirmModalMessage()"
      (closeModal)="close()">
  </app-confirm-modal>

  <app-confirm-modal #confirmSuccess
      [memoDetail]="memo"
      (closeModal)="closeToggle('success')">
  </app-confirm-modal>

  <app-otp-modal [(otp)]="otp"
      [isSaved]="isLoading"
      (otpChange)="submit()"></app-otp-modal>
</div>

<app-memo-preview *ngIf="previewModal"
    [idMemo]="memoId"
    [document]="pdfSrc"
    [fileName]="documentName"
    [downloadUrl]="memo?.signed_document"
    (closed)="previewModal = false">
</app-memo-preview>

<app-confirm-modal
  *ngIf="memo"
  #confirmSuccess
  [memo]="memo"
  (closeModal)="closeToggle('success')">
</app-confirm-modal>

<app-reviewer-modal
  #reviewer
  [memo]="memo"
  (confirm)="review($event)"
  (closeModal)="closeToggle('success')">
</app-reviewer-modal>

<ng-template #announcementPopup
    let-c="close">
  <app-modal-confirmation [title]="editDepartmentTextHeader()">
    <ng-template appCustomModalBody>
      <div class="modal-body p-3 custom-box modal-height">
        <div *ngIf="editDept">
          <label class="pl-3"
              [style.color]="'#4d4d4d'">
            {{'MEMOS.DEPARTMENT' | translate}}
          </label>
          <div>
            <div class="form-group col-lg-12 col-sm-12">
              <app-tree-dropdown [multiple]="true"
                  [isAllDepartment]="memo.is_announce_all_dept"
                  [placeholder]="'MEMOS.ANNOUNCEMENT-HEADER'| translate"
                  [(model)]="dptAnnouncement"></app-tree-dropdown>
            </div>
            <div class="col-12 mt-2 p-0 text-right pointer"
                (click)="memo.is_require_acknowledge = !memo.is_require_acknowledge">
              <div class="custom-control custom-switch">
                <input type="checkbox"
                    class="custom-control-input"
                    [ngModel]="memo.is_require_acknowledge">
                <label class="custom-control-label pointer pr-3 pt-1px">
                  {{'FORM.Require acknowledgment' |translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="contents"
            *ngIf="!editDept">
          <div *ngFor="let announcements of deptList |slice : startItem-1 : lastItem; let i=index">
            <div class="mb-3">
              {{(itemsPerPage * (currentPage - 1)) + i + 1 }}. {{translate.currentLang === 'en' ?
              announcements.display_name_en : announcements.display_name}}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template appCustomModalFooter>
      <div class="modal-footer grid">
        <div class="col-12 m-0 text-right">
          <ng-container *ngIf="!editDept;else showBtn">
            <app-pagination [count]="count"
                [boundaryLinks]="true"
                [noneSize]="true"
                [newDesign]="true"
                [page]="currentPage"
                [(pageSize)]="itemsPerPage"
                (pageChange)="listCount($event)"
                (pageSizeChange)="listCount($event)"></app-pagination>
          </ng-container>
          <ng-template #showBtn>
            <button class="btn btn-outline mr-2 "
                type="button"
                [style.--c]="themeList?.header_table_color"
                (click)="c()">
              {{"MEMOS.CANCEL" | translate}}</button>
            <button appSetTheme
                class="btn btn-confirm"
                type="button"
                [ladda]="isSaveAnnounce"
                (click)="saveAnnouncement()">
              {{"MEMOS.SAVE" | translate}}</button>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
